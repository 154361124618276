import React, { useState, useEffect, useContext } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { Row, Col, Form } from 'antd';
import { useRouter } from 'next/router';
import { SubmitButton } from '../admin/components/Buttons';
import styles from '../styles/error.module.scss';

const FourOhFour = () => {
  const router = useRouter();

  const onSendEmail = () => {
    document.location.href = 'mailto:aposto@lizard-apps.com';
  };

  return (
    <div className={styles.container}>
      <div className={styles.backgroundLogo}>
        <img
          src="/images/aposto-logo-long.png"
          className={styles.backgroundImage}
        />
      </div>
      <Row className={styles.main}>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 10, offset: 2 }}
          lg={{ span: 6, offset: 6 }}
          className={styles.leftContainer}
        >
          <div className={styles.offsideContainer}>
            <img src="/images/offside.png" className={styles.offsideImage} />
          </div>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 11, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          className={styles.rightContainer}
        >
          <div className={styles.title}>Oops! Something went wrong!</div>
          <div className={styles.link}>
            <div className={styles.linkText} onClick={() => router.back()}>
              Go Back
            </div>
            <div className={styles.linkText} onClick={() => router.reload()}>
              Refresh Page
            </div>
            <div
              className={styles.linkText}
              onClick={() => router.replace('/admin')}
            >
              Dashboard
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.reportTitle}>Lets fix this</div>
            <div className={styles.reportDescription}>
              {`We're not sure how this happened but to ensure it does not happen
              again, please help us by reporting what steps you took to get here`}
            </div>
            <div className={styles.reportButton} onClick={onSendEmail}>
              Report issue
            </div>
          </div>
          <div
            className={styles.mobileLink}
            onClick={() => router.replace('/app')}
          >
            Take me back to the home screen
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FourOhFour;